import React from "react";
import { useState } from "react";
// @material-ui/core components
// core components
//import CustomInput from "components/CustomInput/CustomInput.js";
// import Lucratividade from "./Lucratividade";
import Movimentacoes from "./Movimentacoes";
import Lucratividade from "./Lucratividade";
import Vencimentos from "./Vencimentos";
import ExportacaoParcelasVirtuoso from "./ExportacaoParcelasVirtuoso";
import ExportacaoLiquidacoes from "./ExportacaoLiquidacoes";
import Button from "components/CustomButtons/Button";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import { triggerBase64Download } from "common-base64-downloader-react";
import Parcelas from "./Parcelas";
const { getAutenticado } = require("../../js_utils/util");
const { API_URL } = require("../../consts");

export default function Relatorios() {
  //Snackbar
  let [mensagemErro, setMensagemErro] = useState("");
  const [alerta, setAlerta] = useState(false);
  const [cor, setCor] = useState("warning");
  const showNotification = () => {
    if (!alerta) {
      setAlerta(true);
      setTimeout(function () {
        setAlerta(false);
      }, 6000);
    }
  };
  const mostraMensagem = (msg, cor) => {
    setCor(cor);
    setMensagemErro(msg);
    showNotification();
  };
  const [opcao, setOpcao] = useState(2);
  const iniciaDownloadProposta = async () => {
    const resultados = await getAutenticado(API_URL + "propostasplanilha/");
    if (resultados.data.sucesso)
      triggerBase64Download(resultados.data.data, "propostas");
    else {
      mostraMensagem("Problema ao carregar a planilha", "danger");
    }
  };
  return (
    <React.Fragment>
      <Button color="info" onClick={() => setOpcao(1)}>
        Lucratividade
      </Button>
      <Button color="info" onClick={() => setOpcao(2)}>
        Movimentações
      </Button>
      <Button color="info" onClick={() => setOpcao(3)}>
        Vencimentos
      </Button>
      <Button color="info" onClick={() => setOpcao(4)}>
        Exportação Arquivo Virtuoso
      </Button>
      <Button color="info" onClick={() => setOpcao(5)}>
        Exportação Liquidações
      </Button>
      <Button color="info" onClick={() => setOpcao(6)}>
        Parcelas
      </Button>
      <Button color="info" onClick={iniciaDownloadProposta}>
        Baixar Propostas
      </Button>
      {opcao === 1 ? (
        <Lucratividade />
      ) : opcao === 2 ? (
        <Movimentacoes />
      ) : opcao === 3 ? (
        <Vencimentos />
      ) : opcao === 4 ? (
        <ExportacaoParcelasVirtuoso />
      ) : opcao === 5 ? (
        <ExportacaoLiquidacoes />
      ) : (
        <Parcelas />
      )}
      <Snackbar
        place="bc"
        color={cor || "warning"}
        icon={AddAlert}
        message={mensagemErro}
        open={alerta}
        closeNotification={() => setAlerta(false)}
        close
      />
    </React.Fragment>
  );
}
