import React, { useState } from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
// core components
import { makeStyles } from "@material-ui/core/styles";
import Logout from "components/Logout";
//import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import Button from "components/CustomButtons/Button";
import DateAdapter from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { API_URL } from "consts";
import Table from "components/Table/Table.js";

const {
  getAutenticado,
  formataDataSQL,
  formataValorReais,
  formataData,
  mascaraPhone,
} = require("../../js_utils/util");

const styleProtestado = {
  color: "purple",
  fontWeight: "bold",
};

const styleCobrancaExterna = {
  color: "yellow",
  backgroundColor: "gray",
  fontWeight: "bold",
};

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const MILI_TO_SEVEN_DAYS = 1000 * 60 * 60 * 24 * 7;
export default function Parcelas() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const logado = true;

  //Snackbar
  let [mensagemErro, setMensagemErro] = useState("");
  const [alerta, setAlerta] = useState(false);
  const [cor, setCor] = useState("warning");
  const [dataVencDe, setDataVencDe] = useState(new Date());
  const [results, setResults] = useState([]);
  const [dataVencAte, setDataVencAte] = useState(
    new Date(Date.now() + MILI_TO_SEVEN_DAYS)
  );

  const showNotification = () => {
    if (!alerta) {
      setAlerta(true);
      setTimeout(function () {
        setAlerta(false);
      }, 6000);
    }
  };
  const mostraMensagem = (msg, cor) => {
    setCor(cor);
    setMensagemErro(msg);
    showNotification();
  };

  const fetchParcelas = async () => {
    const de = formataDataSQL(dataVencDe);
    const ate = formataDataSQL(dataVencAte);
    try {
      const result = await getAutenticado(
        `${API_URL}parcelas/por-vencimento?de=${de}&ate=${ate}`
      );
      const res = result.data.data;
      if (res.length < 1) {
        mostraMensagem("Sem dados para o período", "info");
      }
      setResults(res);
    } catch (error) {
      setResults([]);
      mostraMensagem("Erro", "error");
    }
  };

  return logado ? (
    <div>
      <Card>
        <CardHeader color="primary">
          <h5 className={classes.cardTitleWhite}>Parcelas por Vencimento</h5>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <GridItem>
                <DesktopDatePicker
                  label="De"
                  inputFormat="dd/MM/yyyy"
                  value={dataVencDe}
                  onChange={(val) => {
                    setDataVencDe(val);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </GridItem>
              <GridItem>
                <DesktopDatePicker
                  label="Até"
                  inputFormat="dd/MM/yyyy"
                  value={dataVencAte}
                  onChange={(val) => {
                    setDataVencAte(val);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </GridItem>
              <Button color="success" onClick={() => fetchParcelas()}>
                Pesquisar
              </Button>
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  "Cliente",
                  "#",
                  "Vlr Parcela",
                  "Saldo",
                  "Venc.",
                  "Fone",
                  "Loja",
                  "Obs.",
                ]}
                tableData={results.map((linha) => [
                  <Link
                    to={`/admin/clientes/${linha.CPF}`}
                    target="#"
                    key={linha.CPF}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {linha.NOME}
                  </Link>,
                  `#${linha.PROPOSTA}/${linha.NUMERO}`,
                  formataValorReais(linha.VLR_PARCELA),
                  formataValorReais(linha.VLR_SALDO),
                  formataData(linha.VENCIMENTO),
                  mascaraPhone(linha.TELEFONE),
                  linha.RAZAO_SOCIAL,
                  <>
                    {linha.DATA_COBRANCA_EXTERNA && (
                      <p
                        key={`#${linha.PROPOSTA}/${linha.NUMERO}`}
                        style={styleCobrancaExterna}
                      >
                        Cob. Externa
                      </p>
                    )}
                    {linha.DATA_PROTESTO && (
                      <p
                        key={`#${linha.PROPOSTA}/${linha.NUMERO}`}
                        style={styleProtestado}
                      >
                        Protesto
                      </p>
                    )}
                  </>,
                ])}
              />
            </LocalizationProvider>
          </GridContainer>
        </CardBody>
      </Card>
      <Snackbar
        place="bc"
        color={cor || "warning"}
        icon={AddAlert}
        message={mensagemErro}
        open={alerta}
        closeNotification={() => setAlerta(false)}
        close
      />
    </div>
  ) : (
    <Logout></Logout>
  );
}
