import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Logout from "components/Logout";
//import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody";
import logout from "js_utils/logout";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import DateAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button";

const {
  getAutenticado,
  formataValorReais,
  formataDataSQL,
} = require("../../js_utils/util");

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function Lucratividade() {
  const resumo = (dados) => (
    <Card key={dados.lojista.CNPJ}>
      <CardHeader color="primary">
        <h5 className={classes.cardTitleWhite}>{dados.lojista.NOME}</h5>
      </CardHeader>
      <CardBody>
        <table>
          <tbody>
            <tr>
              <td>Empréstimos:</td>
              <td>{dados.numEmprestimos}</td>
            </tr>
            <tr>
              <td>Valor Bruto dos Empréstimos:</td>
              <td style={{ textAlign: "right" }}>
                {formataValorReais(dados.vlrParcelas)}
              </td>
            </tr>
            <tr>
              <td>Valor Repasses ao Lojista:</td>
              <td style={{ textAlign: "right" }}>
                {formataValorReais(dados.vlrFinanciado)}
              </td>
            </tr>
            <tr>
              <td>Valor Líquido (Juros):</td>
              <td style={{ textAlign: "right" }}>
                {formataValorReais(dados.vlrLiquido)}
              </td>
            </tr>
            <tr>
              <td>Valor Bruto Recebido:</td>
              <td style={{ textAlign: "right" }}>
                {formataValorReais(parseFloat(dados.vlrRecebido))}
              </td>
            </tr>
            <tr>
              <td>Valor a Receber em Aberto:</td>
              <td style={{ textAlign: "right" }}>
                {formataValorReais(dados.vlrEmAberto)}
              </td>
            </tr>
            <tr>
              <td>(L) Valor Recebido Líquido (Juros):</td>
              <td style={{ textAlign: "right" }}>
                {formataValorReais(dados.vlrRecebidoLiquido)}
              </td>
            </tr>
            <tr>
              <td>Valor Vencido:</td>
              <td style={{ textAlign: "right" }}>
                {formataValorReais(dados.vlrVencido)}
              </td>
            </tr>
            <tr>
              <td>(P) Prejuizo com parcelas vencidas:</td>
              <td style={{ textAlign: "right" }}>
                {formataValorReais(dados.vlrPrejuizo)}
              </td>
            </tr>
            <tr style={{ fontWeight: "bold" }}>
              <td>(L) - (P) Resultado Líquido:</td>
              <td style={{ textAlign: "right" }}>
                {formataValorReais(
                  parseFloat(
                    dados.vlrRecebidoLiquido ? dados.vlrRecebidoLiquido : 0
                  ) - parseFloat(dados.vlrPrejuizo ? dados.vlrPrejuizo : 0)
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
  const { API_URL } = require("../../consts");
  //let [logado, setLogado] = useState(true);
  const logado = true;
  const [dados, setDados] = useState([]);

  //Snackbar
  let [mensagemErro, setMensagemErro] = useState("");
  const [alerta, setAlerta] = useState(false);
  const [cor, setCor] = useState("warning");
  const showNotification = () => {
    if (!alerta) {
      setAlerta(true);
      setTimeout(function () {
        setAlerta(false);
      }, 6000);
    }
  };
  const mostraMensagem = (msg, cor) => {
    setCor(cor);
    setMensagemErro(msg);
    showNotification();
  };
  useEffect(async () => {
    loadFromDate("");
  }, []);
  const loadFromDate = async (dtBase) => {
    const query = dtBase && dtBase.trim() !== "" ? "?dtBase=" + dtBase : "";
    try {
      let resultados = await getAutenticado(
        API_URL + "relatoriolucratividade" + query
      );
      if (resultados.data.sucesso) {
        let dadosEmp = resultados.data.resultados;
        let total = {
          lojista: {
            ID: "0",
            CNPJ: "1",
            NOME: "TOTAIS",
          },
          numEmprestimos: 0,
          numParcelas: 0,
          numParcelasVencidas: 0,
          vlrEmAberto: 0,
          vlrFinanciado: 0,
          vlrLiquido: 0,
          vlrParcelas: 0,
          vlrPrejuizo: 0,
          vlrRecebido: 0,
          vlrRecebidoLiquido: 0,
          vlrVencido: 0,
        };
        Object.keys(total).forEach((key) => {
          dadosEmp.forEach((d) => {
            if (key != "lojista") total[key] += d[key] ? parseFloat(d[key]) : 0;
          });
        });
        dadosEmp.push(total);
        console.log(dadosEmp.map((e) => e.lojista));
        setDados(dadosEmp);
      } else {
        mostraMensagem("Erro interno.", "danger");
      }
    } catch (error) {
      mostraMensagem(JSON.stringify(error), "danger");
      logout();
    }
  };
  const reload = async () => {
    const dtStr = dtEmissao ? formataDataSQL(dtEmissao) : "";
    loadFromDate(dtStr);
  };
  const classes = useStyles();
  const [dtEmissao, setDtEmissao] = useState(null);
  const [error, setError] = useState(false);
  return logado ? (
    <div>
      Data de Emissão a partir de:
      <br />
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DesktopDatePicker
          label="Emissão a partir de"
          inputFormat="dd/MM/yyyy"
          value={dtEmissao}
          onError={(newError) => {
            console.log(newError);
            setError(newError ? true : false);
          }}
          onChange={(val) => {
            setDtEmissao(val);
            setError(false);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <br />
      <Button color="info" disabled={error} onClick={reload}>
        Recarregar
      </Button>
      {dados.map((dado) => resumo(dado))}
      <Snackbar
        place="bc"
        color={cor || "warning"}
        icon={AddAlert}
        message={mensagemErro}
        open={alerta}
        closeNotification={() => setAlerta(false)}
        close
      />
    </div>
  ) : (
    <Logout></Logout>
  );
}
