import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar";
import Error from "@material-ui/icons/Error";
import image from "assets/img/venda-assegurada.jpg";

const axios = require("axios");
const { API_URL } = require("../consts");
const loginUrl = API_URL + "auth/login";

const useStyles = makeStyles(styles);

export default function Login() {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [mensagemErro, setMensagemErro] = useState("");
  const [alerta, setAlerta] = useState(false);
  const showNotification = () => {
    if (!alerta) {
      setAlerta(true);
      setTimeout(function () {
        setAlerta(false);
      }, 6000);
    }
  };
  const history = useHistory();
  const handleLogin = async () => {
    let response = "";
    try {
      response = await axios.post(loginUrl, {
        username: username,
        password: password,
      });
      localStorage.setItem("vsTokenAdmin", response.data.token);
      history.push("/admin/user");
    } catch (error) {
      console.log(Error);
      if (error.response)
        setMensagemErro(error.response.data.mensagem || error.message);
      else setMensagemErro("Login falhou. Tente mais tarde");
      showNotification();
    }
  };

  return (
    <div style={{ backgroundImage: "url(" + image + ")" }}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>Venda Assegurada</h4>
              <p className={classes.cardCategoryWhite}>
                {" "}
                Login - Área Administrativa
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Login"
                    id="login"
                    onChange={(e) => setUsername(e.target.value)}
                    formControlProps={{
                      fullWidth: false,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    hidden={true}
                    labelText="Senha"
                    id="senha"
                    onChange={(e) => setPassword(e.target.value)}
                    formControlProps={{
                      fullWidth: false,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <Button color="success" onClick={handleLogin}>
                    Entrar
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <Snackbar
          place="bc"
          color="danger"
          icon={Error}
          message={mensagemErro}
          open={alerta}
          closeNotification={() => setAlerta(false)}
          close
        />
      </GridContainer>
    </div>
  );
}
