import React, { useState, useEffect } from "react";
// @material-ui/core components
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button";
import { Modal, Box, Typography } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import Logout from "components/Logout";
import EmprestimoCard from "./EmprestimoCard";
import DateAdapter from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import "react-datepicker/dist/react-datepicker.css";
import { Clear } from "@material-ui/icons";
const { API_URL } = require("../../consts");
const {
  getAutenticado,
  formataDataHora,
  postAutenticado,
  formataData,
  formataValorReais,
  deleteAutenticado,
} = require("../../js_utils/util");

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    overflowX: "auto",
    overflowY: "auto",
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
};

export default function EmprestimoNovo() {
  let [logado, setLogado] = useState(true);
  let [dados, setDados] = useState(null);
  const [obsAdmin, setObsAdmin] = useState([]);
  const [propostaSelecionada, setPropostaSelecionada] = useState([]);
  const [modalObsVisible, setModalObsVisible] = useState(false);
  const [modalProtVisible, setModalProtVisible] = useState(false);
  const [modalCobrancaVisible, setModalCobrancaVisible] = useState(false);
  const [idCliSelecionado, setIdCliSelecionado] = useState(0);
  const [dataProtesto, setDataProtesto] = useState(new Date());
  const [dataCobranca, setDataCobranca] = useState(new Date());
  const [parcelaProtesto, setParcelaProtesto] = useState(null);
  const [parcelaCobrancaExterna, setParcelaCobrancaExterna] = useState(null);
  const [obsProtesto, setObsProtesto] = useState([]);
  const [obsCobranca, setObsCobranca] = useState([]);
  const [empresaCobSelecionada, setEmpresaCobSelecionada] = useState(null);

  const [empresasCobranca, setEmpresasCobranca] = useState([]);

  const dataAtual = new Date();

  const [alerta, setAlerta] = useState(false);
  let [mensagemErro, setMensagemErro] = useState("");
  const [cor, setCor] = useState("warning");
  const showNotification = () => {
    if (!alerta) {
      setAlerta(true);
      setTimeout(function () {
        setAlerta(false);
      }, 6000);
    }
  };
  const mostraErro = (mensagem) => {
    setCor("danger");
    setMensagemErro(mensagem);
    showNotification();
  };
  const mostraAcerto = (mensagem) => {
    setCor("success");
    setMensagemErro(mensagem);
    showNotification();
  };

  const toggleCliSelecionado = (id) => {
    if (idCliSelecionado === 0) {
      setIdCliSelecionado(id);
    } else {
      setIdCliSelecionado(0);
    }
  };
  const buscaEmprestimos = async () => {
    let resultado;
    try {
      resultado = await getAutenticado(API_URL + "emprestimos-paged");
      setDados(resultado.data);
    } catch (error) {
      console.log(error);
      console.log(JSON.stringify(resultado));
      if (error.response && error.response.status == 400) setLogado(false);
      resultado = null;
    }
  };

  const buscaEmpresasCobranca = async () => {
    let resultado;
    try {
      resultado = await getAutenticado(API_URL + "empresascobranca");
      setEmpresasCobranca(resultado.data.data);
    } catch (error) {
      console.log(error);
      console.log(JSON.stringify(resultado));
      if (error.response && error.response.status == 400) setLogado(false);
      resultado = null;
    }
  };

  const tentaAlterarVencimento = async (id, vencimento) => {
    try {
      const venc = vencimento.toISOString().substring(0, 10);
      const resultado = await postAutenticado(API_URL + "vencimento", {
        parcela: id,
        vencimento: venc,
      });
      if (resultado.data) {
        if (resultado.data.sucesso) {
          mostraAcerto(resultado.data.mensagem);
          return true;
        } else if (resultado.data.mensagem) {
          mostraErro(resultado.data.mensagem);
        } else {
          mostraErro("Erro interno");
        }
      }
    } catch (error) {
      mostraErro(error.message);
    }
    return false;
  };

  const excluiParcela = async (idProposta) => {
    try {
      const resultado = await postAutenticado(API_URL + "excluiemprestimo", {
        idProposta,
      });
      if (resultado.data && resultado.data.sucesso) {
        mostraAcerto(resultado.data.mensagem);
        buscaEmprestimos();
      } else {
        mostraErro(resultado.data.mensagem);
      }
    } catch (error) {
      mostraErro("Problema ao processar a requisição");
    }
  };

  const desfazRenegociacao = async (idProposta) => {
    try {
      const resultado = await deleteAutenticado(
        API_URL + "renegociacao/" + idProposta
      );
      if (resultado.data && resultado.data.sucesso) {
        mostraAcerto(resultado.data.mensagem);
        buscaEmprestimos();
      } else {
        mostraErro(resultado.data.mensagem);
      }
    } catch (error) {
      if (error?.response?.data?.mensagem) {
        mostraErro(error?.response?.data?.mensagem);
      } else mostraErro("Problema ao processar a requisição");
    }
  };

  const verificaPossibilidadeExclusao = async (idProposta) => {
    try {
      const resultado = await getAutenticado(
        API_URL + `podeexcluir/${idProposta}`
      );
      return resultado.data;
    } catch (error) {
      return {
        sucesso: false,
      };
    }
  };

  const deleteEnvioBoleto = async (idEnvio) => {
    try {
      const resultado = await deleteAutenticado(API_URL + "boletos/" + idEnvio);
      if (resultado.data && resultado.data.sucesso) {
        mostraAcerto(resultado.data.mensagem);
        buscaEmprestimos();
      } else {
        mostraErro(resultado.data.mensagem);
      }
    } catch (error) {
      if (error?.response?.data?.mensagem) {
        mostraErro(error?.response?.data?.mensagem);
      } else mostraErro("Problema ao processar a requisição");
    }
  };

  const enviaBoleto = async (idProposta, observacao) => {
    try {
      const resultado = await postAutenticado(API_URL + "boletos/", {
        PROPOSTA: idProposta,
        OBSERVACAO: observacao,
      });
      if (resultado.data && resultado.data.sucesso) {
        mostraAcerto(resultado.data.mensagem);
        buscaEmprestimos();
      } else {
        mostraErro(resultado.data.mensagem);
      }
    } catch (error) {
      if (error?.response?.data?.mensagem) {
        mostraErro(error?.response?.data?.mensagem);
      } else mostraErro("Problema ao processar a requisição");
    }
  };

  const emprestimoToGridItem = (empr) => {
    if (idCliSelecionado === 0 || idCliSelecionado === empr.ID_CLIENTE) {
      return (
        <GridItem xs={12} sm={6} md={4} key={empr.ID}>
          <EmprestimoCard
            emprestimo={empr}
            tentaAlterarVencimento={tentaAlterarVencimento}
            setDados={setDados}
            setObsAdmin={setObsAdmin}
            setModalObsVisible={setModalObsVisible}
            setModalProtVisible={setModalProtVisible}
            setModalCobrancaVisible={setModalCobrancaVisible}
            setPropostaelecionada={setPropostaSelecionada}
            setPropObsId={setPropObsId}
            idCliSelecionado={idCliSelecionado}
            toggleCliSelecionado={toggleCliSelecionado}
            verificaPossibilidadeExclusao={verificaPossibilidadeExclusao}
            excluiParcela={excluiParcela}
            desfazRenegociacao={desfazRenegociacao}
            mostraErro={mostraErro}
            enviaBoleto={enviaBoleto}
            apagaBoleto={deleteEnvioBoleto}
          />
        </GridItem>
      );
    } else {
      return null;
    }
  };
  useEffect(async () => {
    buscaEmpresasCobranca();
    buscaEmprestimos();
  }, []);
  const [enableGravarObs, setEnableGravarObs] = useState(false);
  const [novaObs, setNovaObs] = useState("");
  const [propObsId, setPropObsId] = useState(0);
  const handleObs = (e) => {
    const { value } = e.target;
    if (value && value.length > 0) {
      setEnableGravarObs(true);
    } else {
      setEnableGravarObs(false);
    }
    setNovaObs(value);
  };
  const tentaGravarObs = async () => {
    setModalObsVisible(false);
    setNovaObs("");
    try {
      const res = await postAutenticado(API_URL + "addobservacao", {
        observacao: {
          OBSERVACAO: novaObs,
          PROPOSTA: propObsId,
        },
      });
      if (res.data && res.data.sucesso) {
        mostraAcerto(res.data.mensagem);
        setNovaObs("");
      } else if (res.data && res.data.mensagem) {
        mostraErro(res.data.mensagem);
      } else {
        mostraErro("Erro interno");
      }
    } catch (e) {
      mostraErro(e.message);
    } finally {
      buscaEmprestimos();
    }
  };

  const tentaRegistrarProtesto = async (parcela, data, obs) => {
    setModalProtVisible(false);
    setObsProtesto("");
    try {
      const res = await postAutenticado(API_URL + "registraprotesto", {
        parcela,
        dtProtesto: data.toISOString().substring(0, 10),
        observacao: obs,
      });
      if (res.data && res.data.sucesso) {
        mostraAcerto(res.data.mensagem);
      } else if (res.data && res.data.mensagem) {
        mostraErro(res.data.mensagem);
      } else {
        mostraErro("Erro interno");
      }
    } catch (e) {
      mostraErro(e.message);
    } finally {
      buscaEmprestimos();
    }
  };

  const tentaRegistrarCobrancaExterna = async (
    parcela,
    data,
    cobrador,
    obs
  ) => {
    setModalCobrancaVisible(false);
    setObsCobranca("");
    try {
      const res = await postAutenticado(API_URL + "registracobrancaexterna", {
        parcela,
        dtRegistro: data.toISOString().substring(0, 10),
        cobrador,
        observacao: obs,
      });
      if (res.data && res.data.sucesso) {
        mostraAcerto(res.data.mensagem);
      } else if (res.data && res.data.mensagem) {
        mostraErro(res.data.mensagem);
      } else {
        mostraErro("Erro interno");
      }
    } catch (e) {
      mostraErro(e.message);
    } finally {
      buscaEmprestimos();
    }
  };
  const boxObservacao = (
    <Modal open={modalObsVisible} onClose={() => setModalObsVisible(false)}>
      <Box style={styles.modalBox}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Observações
        </Typography>
        {obsAdmin.length > 0 ? (
          <table>
            <thead>
              <tr>
                <td>Admin</td>
                <td>Observação</td>
                <td>Data/hora</td>
              </tr>
            </thead>
            <tbody>
              {obsAdmin.map((ob) => (
                <tr key={ob.ID}>
                  <td>{ob.ADMIN}</td>
                  <td style={{ whiteSpace: "pre-line" }}>{ob.OBSERVACAO}</td>
                  <td>{formataDataHora(ob.DATA_HORA)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        <TextField
          label="Cadastrar observação"
          multiline={true}
          fullWidth={true}
          value={novaObs}
          onChange={handleObs}
        ></TextField>
        <Button
          color="success"
          disabled={!enableGravarObs}
          onClick={tentaGravarObs}
        >
          Gravar
        </Button>
      </Box>
    </Modal>
  );
  const boxProtesto = (
    <Modal open={modalProtVisible} onClose={() => setModalProtVisible(false)}>
      <Box style={styles.modalBox}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Protestos
        </Typography>
        {propostaSelecionada.length > 0 ? (
          <>
            <table>
              <thead>
                <tr>
                  <td>Parcela</td>
                  <td>Data Protesto</td>
                  <td>Saldo</td>
                  <td>Protestar</td>
                </tr>
              </thead>
              <tbody>
                {propostaSelecionada.map((parc) => (
                  <tr key={parc.ID_PARCELA}>
                    <td>{parc.NUMERO}</td>
                    <td>
                      {parc.DATA_PROTESTO
                        ? formataData(parc.DATA_PROTESTO)
                        : "Protesto não registrado"}
                    </td>
                    <td>{formataValorReais(parc.VLR_SALDO)}</td>
                    <td>
                      {dataAtual.toISOString().substring(0, 10) >
                        parc.VENCIMENTO && parc.VLR_SALDO > 0.0 ? (
                        <input
                          type="radio"
                          name="parc-protesto"
                          value={parc.ID_PARCELA}
                          onClick={() =>
                            setParcelaProtesto({
                              ID: parc.ID_PARCELA,
                              NUMERO: parc.NUMERO,
                            })
                          }
                        />
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                label="Data do Protesto"
                inputFormat="dd/MM/yyyy"
                value={dataProtesto}
                onChange={(val) => {
                  setDataProtesto(val);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <TextField
              label="Cadastrar observação"
              multiline={true}
              fullWidth={true}
              value={obsProtesto}
              onChange={(e) => setObsProtesto(e.target.value)}
            />
          </>
        ) : null}
        <hr />
        <Button
          color="success"
          onClick={() =>
            tentaRegistrarProtesto(
              parcelaProtesto.ID,
              dataProtesto,
              obsProtesto
            )
          }
          disabled={parcelaProtesto == null}
        >
          Registra Protesto{" "}
          {parcelaProtesto ? "Parcela num." + parcelaProtesto.NUMERO : ""}
        </Button>
      </Box>
    </Modal>
  );
  const tentaDeletarCobrancaExterna = async (parcela) => {
    setModalCobrancaVisible(false);
    setObsCobranca("");
    try {
      const res = await deleteAutenticado(
        API_URL + "cobrancasexternas/" + parcela?.ID_PARCELA
      );
      if (res.data && res.data.sucesso) {
        mostraAcerto(res.data.mensagem);
      } else if (res.data && res.data.mensagem) {
        mostraErro(res.data.mensagem);
      } else {
        mostraErro("Erro interno");
      }
    } catch (e) {
      mostraErro(e.message);
    } finally {
      buscaEmprestimos();
    }
  };

  const [parcelaCancelandoCobranca, setParcelaCancelandoCobranca] = useState(
    {}
  );
  const boxCobrancaExterna = (
    <Modal
      open={modalCobrancaVisible}
      onClose={() => setModalCobrancaVisible(false)}
    >
      <Box style={styles.modalBox}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Cobranças Externas
        </Typography>
        {propostaSelecionada.length > 0 ? (
          <>
            <table>
              <thead>
                <tr>
                  <td>Parcela</td>
                  <td>Data Envio Cobrança</td>
                  <td>Saldo</td>
                  <td>Registrar</td>
                  <td>Cancelar</td>
                </tr>
              </thead>
              <tbody>
                {propostaSelecionada.map((parc) => (
                  <tr key={parc.ID_PARCELA}>
                    <td>{parc.NUMERO}</td>
                    <td>
                      {parc.DATA_COBRANCA_EXTERNA
                        ? formataData(parc.DATA_COBRANCA_EXTERNA)
                        : "Cobrança não registrada"}
                    </td>
                    <td>{formataValorReais(parc.VLR_SALDO)}</td>
                    <td>
                      {dataAtual.toISOString().substring(0, 10) >
                        parc.VENCIMENTO &&
                      parc.VLR_SALDO > 0.0 &&
                      !parc.DATA_COBRANCA_EXTERNA ? (
                        <input
                          key={parc.ID_PARCELA}
                          type="radio"
                          name="parc-cobranca-externa"
                          value={parc.ID_PARCELA}
                          onClick={() =>
                            setParcelaCobrancaExterna({
                              ID: parc.ID_PARCELA,
                              NUMERO: parc.NUMERO,
                            })
                          }
                        />
                      ) : null}
                    </td>
                    <td>
                      {parc.DATA_COBRANCA_EXTERNA ? (
                        <Button
                          onClick={() => setParcelaCancelandoCobranca(parc)}
                          color="danger"
                          size="sm"
                        >
                          X
                        </Button>
                      ) : null}
                    </td>
                    <td>
                      {parcelaCancelandoCobranca == parc ? (
                        <>
                          <Button
                            color="info"
                            onClick={() => setParcelaCancelandoCobranca({})}
                            size="sm"
                          >
                            Cancela
                          </Button>
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() =>
                              tentaDeletarCobrancaExterna(
                                parcelaCancelandoCobranca
                              )
                            }
                          >
                            Confirmar Cancelamento
                          </Button>
                        </>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                label="Data do Envio"
                inputFormat="dd/MM/yyyy"
                value={dataCobranca}
                onChange={(val) => {
                  setDataCobranca(val);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <table>
              <tbody>
                {empresasCobranca?.map((ec) => (
                  <tr key={ec.ID}>
                    <td>{ec.NOME}</td>
                    <td>
                      <input
                        type="radio"
                        name="empresa-cobranca-externa"
                        value={ec.ID}
                        onClick={() => {
                          setEmpresaCobSelecionada(ec);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <TextField
              label="Cadastrar observação"
              multiline={true}
              fullWidth={true}
              value={obsCobranca}
              onChange={(e) => setObsCobranca(e.target.value)}
            />
          </>
        ) : null}
        <hr />
        <Button
          color="success"
          onClick={() =>
            tentaRegistrarCobrancaExterna(
              parcelaCobrancaExterna.ID,
              dataCobranca,
              empresaCobSelecionada?.ID,
              obsCobranca
            )
          }
          disabled={parcelaCobrancaExterna == null}
        >
          Registra Cobrança Externa{" "}
          {parcelaCobrancaExterna
            ? "Parcela num." + parcelaCobrancaExterna.NUMERO
            : ""}
        </Button>
      </Box>
    </Modal>
  );

  const [search, setSearch] = useState("");
  return logado ? (
    <div>
      <Box display="flex" alignItems="end">
        <GridItem>
          <TextField
            label="Pesquisa (nome, loja, cpf)"
            value={search}
            onChange={(e) => {
              const s = e.target.value;
              setSearch(s);
            }}
          />
        </GridItem>
        <GridItem>
          <Button
            onClick={() => setSearch("")}
            color={search.toUpperCase().trim() !== "" ? "warning" : undefined}
          >
            <Clear />
          </Button>
        </GridItem>
      </Box>
      <Box>
        <GridItem>
          <h3>Mostrando últimos 20 empréstimos</h3>
        </GridItem>
      </Box>
      <GridContainer>
        {dados
          ? search && search.toUpperCase().trim() !== ""
            ? dados.data
                .filter((e) => {
                  const reg = new RegExp(search.toUpperCase().trim(), "i");
                  const cpfReg = search.replace(/[.-]/, "");
                  return (
                    e.NOME_CLIENTE.match(reg) ||
                    e.NOME_FANTASIA.match(reg) ||
                    e.NOME_ESTAB.match(reg) ||
                    e.CPF.match(cpfReg)
                  );
                })
                .map((e) => emprestimoToGridItem(e))
            : dados.data.map((e) => emprestimoToGridItem(e))
          : null}
      </GridContainer>
      {boxObservacao}
      {boxProtesto}
      {boxCobrancaExterna}
      <Snackbar
        place="bc"
        color={cor || "warning"}
        icon={AddAlert}
        message={mensagemErro}
        open={alerta}
        closeNotification={() => setAlerta(false)}
        close
      />
    </div>
  ) : (
    <Logout></Logout>
  );
}
