import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Logout from "components/Logout";
import Button from "components/CustomButtons/Button.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import Usuarios from "./Usuarios";
const { cidadesCompleto, estadosCompleto } = require("../../variables/geo");
const { API_URL } = require("../../consts");
const {
  getAutenticado,
  postAutenticado,
  mascaraCNPJ,
  mascaraPhone,
  validaEstabelecimento,
  validaEndereco,
  mascaraCEP,
  formataValorReais,
} = require("../../js_utils/util");

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function Estabelecimentos() {
  const classes = useStyles();

  let [logado, setLogado] = useState(true);
  const [estabelecimentos, setEstabelecimentos] = useState([]);
  const [estabSelec, setEstabSelec] = useState(null);
  const [estabNovo, setEstabNovo] = useState(null);
  const [estadoNovo, setEstadoNovo] = useState({
    SIGLA: "MG",
    NOME: "Minas Gerais",
    CODIGO_IBGE: 31,
  });
  const [cidadeNova, setCidadeNova] = useState({
    NOME: "Pompéu",
    CODIGO_IBGE: 3152006,
  });
  const [cidadesEstado, setCidadesEstado] = useState(cidadesCompleto.data[31]);
  const [cidadesEstadoAlt, setCidadesEstadoAlt] = useState(
    cidadesCompleto.data[31],
  );
  const [alerta, setAlerta] = useState(false);

  const [estabAlteracao, setEstabAlteracao] = useState(null);
  const [estadoAlt, setEstadoAlt] = useState({
    SIGLA: "MG",
    NOME: "Minas Gerais",
    CODIGO_IBGE: 31,
  });
  const [cidadeAlt, setCidadeAlt] = useState({
    NOME: "Pompéu",
    CODIGO_IBGE: 3152006,
  });

  let [mensagemErro, setMensagemErro] = useState("");
  const [cor, setCor] = useState("warning");
  const showNotification = () => {
    if (!alerta) {
      setAlerta(true);
      setTimeout(function () {
        setAlerta(false);
      }, 6000);
    }
  };

  const mostraErro = (mensagem) => {
    setCor("danger");
    setMensagemErro(mensagem);
    showNotification();
  };
  const mostraAcerto = (mensagem) => {
    setCor("success");
    setMensagemErro(mensagem);
    showNotification();
  };

  const carregaEstabs = async () => {
    let resultado;
    try {
      resultado = await getAutenticado(API_URL + "estabelecimentos");
      resultado = resultado.data;
      setEstabelecimentos(resultado.resultados);
    } catch (error) {
      console.log(error);
      console.log(JSON.stringify(resultado));
      if (error.response && error.response.status == 401) setLogado(false);
    }
  };

  useEffect(async () => {
    carregaEstabs();
  }, []);

  const estadoChange = (value) => {
    if (value && value.CODIGO_IBGE) {
      setCidadesEstado(cidadesCompleto.data[value.CODIGO_IBGE]);
      setCidadeNova(cidadesCompleto.data[value.CODIGO_IBGE][0]);
      const selec = estadosCompleto.data.find((item) => {
        return item.CODIGO_IBGE === value.CODIGO_IBGE;
      });
      setEstadoNovo(selec);
      cidadeChange(cidadesCompleto.data[value.CODIGO_IBGE][0]);
      // setSelecCidade(true);
    } else {
      setEstadoNovo(estadosCompleto.data[31]);
      setCidadesEstado(cidadesCompleto.data[31]);
      setCidadeNova(cidadesCompleto.data[31][0]);
      // setSelecCidade(false);
    }
  };

  const cidadeChange = (value) => {
    setCidadeNova(value);
    setEstabNovo((prevState) => ({
      ...prevState,
      CIDADE: value,
    }));
  };

  const estadoAltChange = (value) => {
    if (value && value.CODIGO_IBGE) {
      setCidadesEstadoAlt(cidadesCompleto.data[value.CODIGO_IBGE]);
      setCidadeAlt(cidadesCompleto.data[value.CODIGO_IBGE][0]);
      const selec = estadosCompleto.data.find((item) => {
        return item.CODIGO_IBGE === value.CODIGO_IBGE;
      });
      setEstadoAlt(selec);
      cidadeAltChange(cidadesCompleto.data[value.CODIGO_IBGE][0]);
      // setSelecCidade(true);
    } else {
      setEstadoAlt(estadosCompleto.data[31]);
      setCidadesEstadoAlt(cidadesCompleto.data[31]);
      setCidadeAlt(cidadesCompleto.data[31][0]);
      // setSelecCidade(false);
    }
  };

  const cidadeAltChange = (value) => {
    setCidadeAlt(value);
    setEstabAlteracao((prevState) => ({
      ...prevState,
      CIDADE: value.CODIGO_IBGE,
    }));
  };

  const onChangeNovoEstab = (e) => {
    let { name, value } = e.target;

    if (name === "CNPJ") {
      value = value ? value.replace(/\D/g, "") : " ";
    }
    setEstabNovo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const btnUsuarios = (est) => {
    setEstabSelec(est);
  };

  const tentaGravarEstabelecimento = async () => {
    let errors = validaEstabelecimento(estabNovo);
    errors = errors.concat(validaEndereco(estabNovo));
    if (errors.length > 0) {
      const msg = errors.join("\n");
      mostraErro(msg);
      return;
    }
    const estabRequisicao = {
      ...estabNovo,
      CIDADE: estabNovo.CIDADE.CODIGO_IBGE,
    };
    try {
      let result = await postAutenticado(API_URL + "addestabelecimento", {
        estabelecimento: estabRequisicao,
      });
      if (result.data && result.data.sucesso) {
        mostraAcerto(result.data.mensagem);
      } else if (result.data) {
        mostraErro(result.data.mensagem);
      } else {
        mostraErro("Erro interno");
      }
    } catch (error) {
      mostraErro(error.message);
    } finally {
      setEstabNovo(null);
      carregaEstabs();
    }
  };

  const tentaAlterarEstabelecimento = async () => {
    setEstabAlteracao((prevState) => ({
      ...prevState,
      CIDADE: prevState.CIDADE.CODIGO_IBGE,
    }));
    try {
      const result = await postAutenticado(API_URL + "alteraestabelecimento", {
        estabelecimento: estabAlteracao,
      });
      if (result.data) {
        if (result.data.sucesso) {
          mostraAcerto(result.data.mensagem);
        } else {
          mostraErro(result.data.mensagem);
        }
      } else {
        mostraErro("Erroxx interno");
      }
    } catch (e) {
      mostraErro(e.message);
    }
    carregaEstabs();
    setEstabAlteracao(null);
  };

  const onChangeAlterado = (e) => {
    let { value, name } = e.target;

    if (name === "LIMITE_INICIAL") {
      value = value.replace(/\D/g, "");
      value = parseFloat(value) || 0.0;
      value = value / 100.0;
    }
    setEstabAlteracao((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fragmentoEdicaoEstabelecimentos = () => {
    if (estabAlteracao == null) return null;
    return (
      <Card key={estabAlteracao.CNPJ}>
        <CardHeader color="primary">
          <h3 className={classes.cardTitleWhite}>
            {estabAlteracao.RAZAO_SOCIAL}
          </h3>
          <h5 className={classes.cardTitleWhite}>
            {estabAlteracao.NOME_FANTASIA}
          </h5>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem sm={12} md={2}>
              CNPJ: {mascaraCNPJ(estabAlteracao.CNPJ)}
            </GridItem>
            <GridItem sm={12} md={5}>
              <TextField
                fullWidth={true}
                label="Razão Social"
                name="RAZAO_SOCIAL"
                value={estabAlteracao.RAZAO_SOCIAL}
                onChange={onChangeAlterado}
              ></TextField>
            </GridItem>
            <GridItem sm={12} md={5}>
              <TextField
                fullWidth={true}
                label="Nome Fantasia"
                name="NOME_FANTASIA"
                value={estabAlteracao.NOME_FANTASIA}
                onChange={onChangeAlterado}
              ></TextField>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <TextField
                label="Telefone"
                value={mascaraPhone(estabAlteracao.TELEFONE)}
                name="TELEFONE"
                onChange={onChangeAlterado}
              ></TextField>
            </GridItem>
            <GridItem sm={12} md={6}>
              <TextField
                fullWidth={true}
                label="email"
                name="EMAIL"
                value={estabAlteracao.EMAIL}
                onChange={onChangeAlterado}
              ></TextField>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <TextField
                label="Logradouro"
                fullWidth={true}
                value={estabAlteracao.LOGRADOURO}
                name="LOGRADOURO"
                onChange={onChangeAlterado}
              ></TextField>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <TextField
                label="Número"
                value={estabAlteracao.NUMERO}
                name="NUMERO"
                onChange={onChangeAlterado}
              ></TextField>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <TextField
                label="Bairro"
                value={estabAlteracao.BAIRRO}
                name="BAIRRO"
                onChange={onChangeAlterado}
              ></TextField>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Autocomplete
                name="UF"
                style={{ marginTop: 10 }}
                options={estadosCompleto.data}
                getOptionLabel={(option) => option.NOME}
                id="uf-novo"
                onChange={(event, value) => estadoAltChange(value)}
                defaultValue={{
                  SIGLA: "MG",
                  NOME: "Minas Gerais",
                  CODIGO_IBGE: 31,
                }}
                value={estadoAlt}
                renderInput={(params) => (
                  <TextField {...params} label="Estado" />
                )}
                fullWidth={true}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Autocomplete
                style={{ marginTop: 10 }}
                options={cidadesEstadoAlt}
                getOptionLabel={(option) => option.NOME}
                defaultValue={{
                  NOME: "Pompéu",
                  CODIGO_IBGE: 3152006,
                }}
                value={cidadeAlt}
                onChange={(event, value) => cidadeAltChange(value)}
                id="cidade-novo"
                renderInput={(params) => (
                  <TextField {...params} label="Cidade" />
                )}
                fullWidth={true}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <h3>Observações:</h3>
              <TextField
                fullWidth={true}
                multiline={true}
                value={estabAlteracao.OBSERVACAO}
                name="OBSERVACAO"
                onChange={onChangeAlterado}
              ></TextField>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <h3>Limite Base</h3>
              <h5>
                <TextField
                  fullWidth={true}
                  multiline={true}
                  value={formataValorReais(
                    parseFloat(estabAlteracao.LIMITE_INICIAL),
                  )}
                  name="LIMITE_INICIAL"
                  onChange={onChangeAlterado}
                ></TextField>
              </h5>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <h3>Limite Usado:</h3>
              <h5>
                {formataValorReais(parseFloat(estabAlteracao.VAR_LIMITE))}
              </h5>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <h3>Limite Atual:</h3>
              <h5>
                {formataValorReais(
                  parseFloat(estabAlteracao.LIMITE_INICIAL) +
                    parseFloat(estabAlteracao.VAR_LIMITE),
                )}
              </h5>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <Button
                color="success"
                onClick={() => tentaAlterarEstabelecimento()}
              >
                Gravar
              </Button>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <Button color="danger" onClick={() => setEstabAlteracao(null)}>
                Cancelar
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  };

  const viewEstabelecimentos = (
    <GridContainer>
      <GridItem>
        <Card>
          <CardHeader color="primary">
            <h5 className={classes.cardTitleWhite}>Novo Estabelecimento</h5>
          </CardHeader>
          {estabNovo ? (
            <CardBody>
              <GridContainer>
                <GridItem sm={12} md={2}>
                  <TextField
                    label="CNPJ"
                    name="CNPJ"
                    value={estabNovo.CNPJ ? mascaraCNPJ(estabNovo.CNPJ) : ""}
                    onChange={onChangeNovoEstab}
                  ></TextField>
                </GridItem>
                <GridItem sm={12} md={5}>
                  <TextField
                    fullWidth={true}
                    label="Razão Social"
                    name="RAZAO_SOCIAL"
                    value={estabNovo.RAZAO_SOCIAL}
                    onChange={onChangeNovoEstab}
                  ></TextField>
                </GridItem>
                <GridItem sm={12} md={5}>
                  <TextField
                    fullWidth={true}
                    label="Nome Fantasia"
                    name="NOME_FANTASIA"
                    value={estabNovo.NOME_FANTASIA}
                    onChange={onChangeNovoEstab}
                  ></TextField>
                </GridItem>
                <GridItem sm={12} md={6}>
                  <TextField
                    label="Telefone"
                    name="TELEFONE"
                    value={
                      estabNovo.TELEFONE ? mascaraPhone(estabNovo.TELEFONE) : ""
                    }
                    onChange={onChangeNovoEstab}
                  ></TextField>
                </GridItem>
                <GridItem sm={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="email"
                    name="email"
                    value={estabNovo.EMAIL}
                    onChange={onChangeNovoEstab}
                  ></TextField>
                </GridItem>
                <GridItem sm={12}>
                  <TextField
                    fullWidth={true}
                    label="Observação"
                    multiline={true}
                    name="OBSERVACAO"
                    value={estabNovo.OBSERVACAO}
                    onChange={onChangeNovoEstab}
                  ></TextField>
                </GridItem>
                <GridItem sm={12}>
                  <TextField
                    fullWidth={true}
                    label="Logradouro"
                    multiline={true}
                    name="LOGRADOURO"
                    value={estabNovo.LOGRADOURO}
                    onChange={onChangeNovoEstab}
                  ></TextField>
                </GridItem>
                <GridItem sm={12} md={3}>
                  <TextField
                    fullWidth={true}
                    label="Número"
                    multiline={true}
                    name="NUMERO"
                    value={estabNovo.NUMERO}
                    onChange={onChangeNovoEstab}
                  ></TextField>
                </GridItem>
                <GridItem sm={12} md={3}>
                  <TextField
                    fullWidth={true}
                    label="Complemento"
                    multiline={true}
                    name="COMPLEMENTO"
                    value={estabNovo.COMPLEMENTO}
                    onChange={onChangeNovoEstab}
                  ></TextField>
                </GridItem>
                <GridItem sm={12} md={3}>
                  <TextField
                    fullWidth={true}
                    label="Bairro"
                    multiline={true}
                    name="BAIRRO"
                    value={estabNovo.BAIRRO}
                    onChange={onChangeNovoEstab}
                  ></TextField>
                </GridItem>
                <GridItem sm={12} md={3}>
                  <TextField
                    fullWidth={true}
                    label="Cep"
                    multiline={true}
                    name="CEP"
                    value={estabNovo.CEP ? mascaraCEP(estabNovo.CEP) : ""}
                    onChange={onChangeNovoEstab}
                  ></TextField>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Autocomplete
                    name="UF"
                    style={{ marginTop: 10 }}
                    options={estadosCompleto.data}
                    getOptionLabel={(option) => option.NOME}
                    id="uf-edicao"
                    onChange={(event, value) => estadoChange(value)}
                    defaultValue={{
                      SIGLA: "MG",
                      NOME: "Minas Gerais",
                      CODIGO_IBGE: 31,
                    }}
                    value={estadoNovo}
                    renderInput={(params) => (
                      <TextField {...params} label="Estado" />
                    )}
                    fullWidth={true}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Autocomplete
                    style={{ marginTop: 10 }}
                    options={cidadesEstado}
                    getOptionLabel={(option) => option.NOME}
                    defaultValue={{
                      NOME: "Pompéu",
                      CODIGO_IBGE: 3152006,
                    }}
                    value={cidadeNova}
                    onChange={(event, value) => cidadeChange(value)}
                    id="cidade-edicao"
                    renderInput={(params) => (
                      <TextField {...params} label="Cidade" />
                    )}
                    fullWidth={true}
                  />
                </GridItem>
                <GridItem sm={12}>
                  <span>
                    <Button
                      color="success"
                      onClick={tentaGravarEstabelecimento}
                    >
                      Gravar
                    </Button>
                    <Button color="danger" onClick={() => setEstabNovo(null)}>
                      Cancelar
                    </Button>
                  </span>
                </GridItem>
              </GridContainer>
            </CardBody>
          ) : (
            <CardBody>
              <Button
                color="success"
                onClick={() =>
                  setEstabNovo({
                    CIDADE: {
                      NOME: "Pompéu",
                      CODIGO_IBGE: 3152006,
                    },
                  })
                }
              >
                Adicionar Estabelecimento
              </Button>
            </CardBody>
          )}
        </Card>
      </GridItem>
      {estabelecimentos.map((e) =>
        !estabAlteracao || estabAlteracao.ID !== e.ID ? (
          <Card key={e.CNPJ}>
            <CardHeader color="primary">
              <h3 className={classes.cardTitleWhite}>{e.RAZAO_SOCIAL}</h3>
              <h5 className={classes.cardTitleWhite}>{e.NOME_FANTASIA}</h5>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                  <h3>CNPJ:</h3>
                  <h5>{`${mascaraCNPJ(e.CNPJ)}`}</h5>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <h3>Telefone:</h3>
                  <h5>{`${mascaraPhone(e.TELEFONE)}`}</h5>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <h3>Endereço:</h3>
                  <h5>{`${e.LOGRADOURO}, ${e.NUMERO}, ${e.COMPLEMENTO || ""}, ${
                    e.BAIRRO
                  }, ${e.CIDADE}/${e.SIGLA}`}</h5>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <h3>Observações:</h3>
                  <h5>{e.OBSERVACAO}</h5>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <h3>Limite Base</h3>
                  <h5>{formataValorReais(parseFloat(e.LIMITE_INICIAL))}</h5>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <h3>Limite Usado:</h3>
                  <h5>{formataValorReais(parseFloat(e.VAR_LIMITE))}</h5>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <h3>Limite Atual:</h3>
                  <h5>
                    {formataValorReais(
                      parseFloat(e.LIMITE_INICIAL) + parseFloat(e.VAR_LIMITE),
                    )}
                  </h5>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <Button color="info" onClick={() => btnUsuarios(e)}>
                    Usuários
                  </Button>
                </GridItem>
                {!estabAlteracao ? (
                  <GridItem xs={12} sm={6} md={3}>
                    <Button
                      color="warning"
                      onClick={() => {
                        setEstabAlteracao({
                          ...e,
                          CIDADE: e.COD_CIDADE,
                        });
                        const cidade = cidadesCompleto.data[e.COD_ESTADO].find(
                          (cid) => cid.CODIGO_IBGE === e.COD_CIDADE,
                        );
                        const estado = estadosCompleto.data.find(
                          (est) => est.CODIGO_IBGE === e.COD_ESTADO,
                        );
                        setCidadesEstadoAlt(cidadesCompleto.data[e.COD_ESTADO]);
                        setEstadoAlt(estado);
                        setCidadeAlt(cidade);
                      }}
                    >
                      Alterar
                    </Button>
                  </GridItem>
                ) : null}
                {estabAlteracao != null && estabAlteracao.ID === e.ID ? (
                  <GridItem xs={12} sm={6} md={3}>
                    <Button
                      color="success"
                      onClick={tentaAlterarEstabelecimento}
                    >
                      Gravar
                    </Button>
                  </GridItem>
                ) : null}
                {estabAlteracao != null && estabAlteracao.ID === e.ID ? (
                  <GridItem xs={12} sm={6} md={3}>
                    <Button
                      color="danger"
                      onClick={() => setEstabAlteracao(null)}
                    >
                      Cancelar Alterações
                    </Button>
                  </GridItem>
                ) : null}
              </GridContainer>
            </CardBody>
          </Card>
        ) : (
          fragmentoEdicaoEstabelecimentos()
        ),
      )}
    </GridContainer>
  );

  const volta = () => {
    setEstabSelec(null);
  };
  return logado ? (
    <div>
      {estabSelec ? (
        <Usuarios estabelecimento={estabSelec} volta={volta} />
      ) : (
        viewEstabelecimentos
      )}
      <Snackbar
        place="bc"
        color={cor || "warning"}
        icon={AddAlert}
        message={mensagemErro}
        open={alerta}
        closeNotification={() => setAlerta(false)}
        close
      />
    </div>
  ) : (
    <Logout></Logout>
  );
}
